import { Component, OnInit } from '@angular/core'
import io from 'socket.io-client'
import { NavbarComponent } from '../shared/navbar/navbar.component'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Location, PopStateEvent } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { HttpClient, HttpHeaders } from '@angular/common/http'
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  focus: any
  focus1: any
  socket
  url =
    'wss://sapa-wse-e02.egcvi.com/app/30/topctm1_bi_med/websocketstream2?videoSessionId=qocqfw4vqvot3ca3-qocqfw4vqvot3ca3qounuq4gcljeax243bb2e811448e73e352602e8dff91904be966db8f257f7c80-TopCard000000001-9be63b&videoToken=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0b3BjdG0xX2IiLCJleHAiOjE2NjUwNzE2MjMsImlzcyI6IiJ9.vPTtlU1Eld9ZL2Ax2u8-D_NMg0E3bRCI06U9CRNqktA'
  user: any
  cadastro = {
    username: null,
    birthday: null,
    email: null,
    password: null,
    phone: null,
  }
  loginform = {
    email: null,
    password: null,
  }
  loading
  message

  constructor(
    private modalService: NgbModal,
    public location: Location,
    private router: Router,
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
  ) {
    var uid = localStorage.getItem('user_hash')
    if (uid) {
      console.log('User logged', uid)
      this.afs.firestore
        .collection('users')
        .doc(uid)
        .onSnapshot((r) => {
          this.user = r.data()

          let saldo = this.user['balance']
          saldo = saldo.toFixed(4)
          this.user['balance'] = parseFloat(saldo)
          console.log(this.user)
        })
    }
  }

  entrar() {
    if (this.loginform.email && this.loginform.password) {
      this.loading = true
      this.auth
        .signInWithEmailAndPassword(
          this.loginform.email,
          this.loginform.password,
        )
        .then((r) => {
          var uid = r.user.uid
          localStorage.setItem('user_hash', uid)
          window.location.reload()
        })
        .catch((e) => {
          this.message = 'Ops! Algo deu errado. Por favor, tente novamente.'
          this.loading = false
        })
    } else {
      this.message = 'Por favor, preencha todos os campos antes de continuar.'
    }
  }

  cadastrar() {
    if (
      this.cadastro.email &&
      this.cadastro.password &&
      this.cadastro.username &&
      // this.cadastro.birthday &&
      this.cadastro.phone
    ) {
      this.loading = true
      this.auth
        .createUserWithEmailAndPassword(
          this.cadastro.email,
          this.cadastro.password,
        )
        .then(async (r) => {
          // ;(await this.auth.currentUser).sendEmailVerification().then(() => {
          //   console.log('email sent')
          // })
          // this.afAuth.authState.subscribe((r) => {
          //   if (r) {
          var uid = r.user.uid
          this.cadastro['uid'] = uid
          this.cadastro['balance'] = 0
          this.cadastro['createdAt'] = new Date()
          localStorage.setItem('user_hash', uid)
          this.afs.firestore
            .collection('users')
            .doc(uid)
            .set(this.cadastro)
            .then(() => {
              window.location.reload()
            })
        })
        .catch((e) => {
          this.message = 'Ops! Algo deu errado. Por favor, tente novamente.'
          this.loading = false
        })
    } else {
      this.message = 'Por favor, preencha todos os campos antes de continuar.'
    }
  }

  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
      this.modalService
        .open(content, {
          windowClass: 'modal-mini',
          size: 'sm',
          centered: true,
        })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        )
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService
        .open(content, { windowClass: 'modal-danger', centered: true })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        )
    } else {
      this.modalService.open(content, { centered: true }).result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      )
    }
  }

  ngOnInit() {
    // let ms = new MediaSource()
    // let output = document.getElementById('output') as HTMLVideoElement
    // output.src = URL.createObjectURL(ms)
    // ms.onsourceopen = () => {
    //   let buffer = ms.addSourceBuffer(
    //     'video/mp4; codecs="avc1.640028,mp4a.40.2"',
    //   )
    //   this.sockets(buffer)
    // }
    // this.socket = io('wss://sapa-wse-e02.egcvi.com/app/30/topctm1_bi_med/websocketstream2?videoSessionId=qocqfw4vqvot3ca3-qocqfw4vqvot3ca3qounuq4gcljeax243bb2e811448e73e352602e8dff91904be966db8f257f7c80-TopCard000000001-9be63b&videoToken=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0b3BjdG0xX2IiLCJleHAiOjE2NjUwNzE2MjMsImlzcyI6IiJ9.vPTtlU1Eld9ZL2Ax2u8-D_NMg0E3bRCI06U9CRNqktA');
    // setTimeout(() => {
    //   this.socket.emit('{"eventType":"PLAY","stream":"topctm1_bi_med","requestId":0,"requestTimestamp":5912}');
    // }, 5000);
    // this.socket.on('message', (data: any) => {
    //  console.log(data)
    // })
  }

  sockets(buffer) {
    // Create socket
    // let socket;
    // socket = new WebSocket(this.url)
    // socket.binaryType = 'arraybuffer'
    // Log socket opening and closing
    // socket.addEventListener('open', (event) => {
    //   console.log('Websocket connection opened')
    //   socket.send('{"eventType":"PLAY","stream":"topctm1_bi_med","requestId":0,"requestTimestamp":5912}')
    // })
    // socket.addEventListener('close', (event) => {
    //   console.log('Websocket connection closed')
    // })
    // // Handle the message
    // socket.addEventListener('message', (event) => {
    //   // console.log(event.data)
    //   // buffer.appendBuffer(event.data)
    //   buffer.appendBuffer(new Uint8Array(event.data))
    //   // if (event.data instanceof Blob) {
    //   //   reader = new FileReader()
    //   //   reader.onload = () => {
    //   //     console.log('Result: ' + reader.result)
    //   //   }
    //   //   reader.readAsText(event.data)
    //   // } else {
    //   //   console.log('Result: ' + event.data)
    //   // }
    // })
    // console.log('conectar')
    // const socket = new WebSocket(this.url)
    // setTimeout(() => {
    //   console.log(socket.readyState)
    //   socket.send('{"eventType":"PLAY","stream":"topctm1_bi_med","requestId":0,"requestTimestamp":5912}')
    // }, 3000)
    // socket.onmessage = async function (event) {
    //   console.log('message', event)
    //   // event.data is a blob
    //   buffer.appendBuffer(event.data)
    // }
  }
}
