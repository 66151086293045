import { Component, OnInit, ViewChild, NgZone } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  socket;
  @ViewChild('chart', { static: false }) mychart
  level = 1
  type = 'LineChart'
  data = [[0, 0]]
  crashed = true
  columnNames = ['Tempo', 'Multiplicador']
  options = {
    colors: ['#B6FF03', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
    is3D: false,
    backgroundColor: '#0e1923',
    vAxis: {
      textStyle: { color: '#5e6577' },
      gridlines: {
        color: 'transparent',
        minSpacing: 25,
      },
      minValue: 5,
    },
    hAxis: {
      textStyle: { color: '#5e6577' },
      gridlines: {
        color: 'transparent',
        minSpacing: 0,
      },
      minValue: 5,
    },
    legend: { position: 'none' },
    isStacked: true,
  }
  dynamicResize = true
  height = 400
  progress = 0
  ready = false
  valor_entrada
  user
  apostas = []
  showapostar = true
  apostou = false
  saiudarodada = false
  comecou = false
  padrao_rodadas = 10
  rodadas_prejuizo = 0
  rodadas_lucro = 0
  lucroscasa = 0
  lucroscliente = 0
  resultadosdosclientes = 0
  totaldeapostas = 0
  history = []
  historyclient = []
  currentid
  valor_apostado
  apostados = []
  names = ['Luan', 'Pedro', 'Caio', 'Mariana', 'Lígia', 'Rafaela']
  entrarnaproximarodada = false
  valorproxima = 0

  apostadores;
  apostadoresDelay;

  constructor(
    private modalService: NgbModal,
    private ngZone: NgZone,
    private afs: AngularFirestore,
    private httpClient: HttpClient
  ) {
  }

  open(content, type, modalDimension) {
    if (modalDimension === 'sm' && type === 'modal_mini') {
      this.modalService
        .open(content, {
          windowClass: 'modal-mini',
          size: 'sm',
          centered: true,
        })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        )
    } else if (modalDimension === '' && type === 'Notification') {
      this.modalService
        .open(content, { windowClass: 'modal-danger', centered: true })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        )
    } else {
      this.modalService.open(content, { centered: true }).result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      )
    }
  }

  ngOnInit() {
    //Iniciar rodadas



    // setInterval(() => {
    //   if (this.progress <= 120) {
    //     if (this.apostadores == null) {
    //       this.httpClient.get('https://gatewaypayments.herokuapp.com/get_gamblers').subscribe(
    //         (r) => {
    //           this.apostadores = r
    //         })
    //     }
    //     if (this.apostadores != null && this.progress <= 1) {
    //       this.apostadores = null
    //     }
    //   }
    // }, 500)


    this.startround()

    //Verify user is logged
    var uid = localStorage.getItem('user_hash')
    if (uid) {
      this.afs.firestore
        .collection('history')
        .where('user', '==', uid)
        .orderBy('date', 'desc')
        .onSnapshot((r) => {
          this.historyclient = []
          r.forEach((rr) => {
            let obj = rr.data()
            if (obj.status != 'pending') {
              obj.retorno = (obj.return - obj.value).toFixed(2)
            } else {
              obj.retorno = (obj.value - obj.value * 2).toFixed(2)
            }

            this.historyclient.push(obj)
          })
        })
      this.afs.firestore
        .collection('users')
        .doc(uid)
        .get()
        .then((r) => {
          this.user = r.data()

          let history = JSON.parse(localStorage.getItem('history'))
          if (history) {
            this.history = history
          }
        })
    }

    setTimeout(() => {
      var input = document.getElementById('inpentrada')

      // Execute a function when the user presses a key on the keyboard
      input.addEventListener('keypress', (event) => {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === 'Enter') {
          //console.log('Entrar')
          // Cancel the default action, if needed
          event.preventDefault()
          // Trigger the button element with a click

          if (!this.apostou && this.comecou) {
            this.entrarnaproxima()
          } else {
            this.entrar()
          }
        }
      })
    })
  }



  entrarnaproxima() {
    if (this.valor_entrada) {
      this.entrarnaproximarodada = true
      this.showapostar = false
      this.valorproxima = this.valor_entrada
    }
  }

  close() {
    this.modalService.dismissAll()
  }

  entrarnaproximaconcluir() {
    if (this.valorproxima && this.showapostar) {
      this.valor_entrada = this.valorproxima
      var uid = localStorage.getItem('user_hash')
      this.showapostar = false
      if (uid) {
        this.afs.firestore
          .collection('users')
          .doc(uid)
          .get()
          .then((r) => {
            this.user = r.data()
            if (this.user.balance >= this.valor_entrada) {
              this.afs.firestore
                .collection('users')
                .doc(uid)
                .update({ balance: this.user.balance - this.valor_entrada })
                .then(() => {
                  this.apostas.push(this.valor_entrada)
                  this.apostou = true
                  this.valor_apostado = this.valor_entrada
                  this.afs.firestore
                    .collection('history')
                    .add({
                      user: this.user.uid,
                      value: this.valor_entrada,
                      status: 'pending',
                      multiplier: 0,
                      date: new Date(),
                    })
                    .then((r) => {
                      this.currentid = r.id
                    })
                })
            }
          })
      }
    }
  }

  entrar() {
    if (this.valor_entrada && this.showapostar && !this.entrarnaproximarodada) {
      var uid = localStorage.getItem('user_hash')
      this.showapostar = false
      if (uid) {
        this.afs.firestore
          .collection('users')
          .doc(uid)
          .get()
          .then((r) => {
            this.user = r.data()
            if (this.user.balance >= this.valor_entrada) {
              this.afs.firestore
                .collection('users')
                .doc(uid)
                .update({ balance: this.user.balance - this.valor_entrada })
                .then(() => {
                  this.apostas.push(this.valor_entrada)
                  this.apostou = true
                  this.valor_apostado = this.valor_entrada
                  this.afs.firestore
                    .collection('history')
                    .add({
                      user: this.user.uid,
                      value: this.valor_entrada,
                      status: 'pending',
                      multiplier: 0,
                      date: new Date(),
                    })
                    .then((r) => {
                      this.currentid = r.id
                    })
                })
            }
          })
      }
    }
  }

  async startround() {
    this.showapostar = true
    this.apostou = false
    this.saiudarodada = false
    this.currentid = null
    // this.valor_entrada = null
    this.ready = false
    this.comecou = false
    this.apostas = []
    this.progress = 0
    if (this.entrarnaproximarodada) {
      this.entrarnaproximaconcluir()
    }
    this.entrarnaproximarodada = false

    return new Promise(async (resolve, reject) => {
      for (let i = 0; this.progress < 120; i += 0.01) {
        this.progress = this.progress + i

        await this.delay2().then(() => {
          return resolve(true)
        })
      }

      this.ready = true
      this.crashed = false
      this.run()
    })
  }

  dividir() {
    if (this.valor_entrada) {
      this.valor_entrada = this.valor_entrada / 2
    }
  }

  dobrar() {
    if (this.valor_entrada) {
      this.valor_entrada = this.valor_entrada * 2
    }
  }

  retirar() {
    if (!this.crashed && this.apostou && !this.saiudarodada) {
      let level = this.level
      //console.log('Retirar em', level)
      this.saiudarodada = true
      let valor = this.valor_apostado * level - this.valor_apostado
      var uid = localStorage.getItem('user_hash')
      this.resultadosdosclientes = this.resultadosdosclientes + valor
      this.totaldeapostas = this.totaldeapostas - this.valor_apostado
      this.afs.firestore
        .collection('users')
        .doc(uid)
        .update({ balance: this.user.balance + valor })

      this.afs.firestore
        .collection('history')
        .doc(this.currentid)
        .update({
          return: this.valor_apostado * level,
          multiplier: this.level,
          status: 'gain',
        })
    }
  }

  async delay2() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 50)
    })
  }

  async run() {
    //Popular nomes
    this.names.forEach(() => {
      var item = this.names[Math.floor(Math.random() * this.names.length)]
      var max = 200
      var min = 50
      this.apostados.push({
        name: item,
        value: (Math.random() * (max - min + 1) + min).toFixed(0),
      })
    })

    this.comecou = true
    //console.log('Apostas', this.apostas)
    this.showapostar = false

    this.data = []
    this.level = 1
    return new Promise(async (resolve, reject) => {
      let min = 1
      let max = 5
      let totarget = parseFloat((Math.random() * (max - min) + min).toFixed(2))
      let target = parseFloat(totarget.toFixed(2))
      let actual = 0
      let seg = 0
      this.totaldeapostas = 0
      this.apostas.forEach((a) => {
        this.totaldeapostas = this.totaldeapostas + a
      })
      //console.log('Total de apostas', this.totaldeapostas)

      //Calcular se é viável
      let viavel = true
      let naoprejuizo = true
      let total_rodadas = this.rodadas_lucro + this.rodadas_prejuizo
      if (total_rodadas == this.padrao_rodadas) {
        this.rodadas_lucro = 0
        this.rodadas_prejuizo = 0
      }

      let totargetrodada = parseFloat(
        (Math.random() * (this.padrao_rodadas - 1) + 1).toFixed(2),
      )
      // //console.log('Target rodada', totargetrodada);
      // let targetrodada = parseFloat(totargetrodada.toFixed(2))
      // if (targetrodada > this.padrao_rodadas / 2) {
      //   if(this.rodadas_prejuizo < 2){
      //     viavel = false;
      //     this.rodadas_lucro = this.rodadas_lucro + 1
      //     //console.log('Gerar lucro para casa');
      //   }
      //   else {
      //     naoprejuizo = false
      //     this.rodadas_prejuizo = this.rodadas_prejuizo + 1
      //     //console.log('Gerar prejuízo para casa');
      //   }
      // }
      //console.log('Lucros da casa', this.lucroscasa)
      //console.log('Lucros do cliente', this.lucroscliente)
      if (this.lucroscasa == 0 && this.lucroscliente == 0) {
        // //console.log('Lucro p/ cliente')
        // naoprejuizo = false
        //console.log('Lucro p/ casa')
        viavel = false
      } else if (this.lucroscasa > this.lucroscliente) {
        //console.log('Lucro p/ cliente')
        naoprejuizo = false
      } else {
        //console.log('Lucro p/ casa')
        viavel = false
      }

      while (!naoprejuizo) {
        let resultados = 0
        this.apostas.forEach((a) => {
          let resultadoprevisivel = a * target - a
          resultados = resultadoprevisivel + resultados
        })

        if (resultados < this.totaldeapostas) {
          totarget = parseFloat((Math.random() * (max - min) + min).toFixed(2))
          target = parseFloat(totarget.toFixed(2))
        } else {
          //LUCRO P/ USUÁRIO
          naoprejuizo = true
        }
      }

      while (!viavel) {
        let resultados = 0
        this.apostas.forEach((a) => {
          let resultadoprevisivel = a * target - a
          resultados = resultadoprevisivel + resultados
        })

        if (resultados > this.totaldeapostas) {
          totarget = parseFloat((Math.random() * (max - min) + min).toFixed(2))
          target = parseFloat(totarget.toFixed(2))
        } else {
          //LUCRO P/ RUBI
          viavel = true
        }
      }

      // //console.log('***TARGET', target);

      this.resultadosdosclientes = 0

      for (let i = 1; i <= target; i += 0.01) {
        let n = parseFloat(i.toFixed(3))

        //Temporizador
        seg = seg + 0.1
        let s = parseFloat(seg.toFixed(2))

        var toincrement = [s, n]
        this.data.push(toincrement)

        this.data = Object.assign([], this.data)

        actual = n
        this.level = n
        await this.delay().then(() => {
          return resolve(true)
        })
      }

      //CRASHED
      this.ngZone.run(() => {
        //console.log('Crashed')
        this.crashed = true

        //Zerar nomes
        this.apostados = []

        //Crashed

        //Lucros clientes
        //console.log('Resultado dos clientes', this.resultadosdosclientes)
        this.lucroscliente = this.lucroscliente + this.resultadosdosclientes

        //Lucros casa
        let lucrocasa = this.totaldeapostas - this.resultadosdosclientes
        //console.log('Resultado da casa', lucrocasa)
        this.lucroscasa = this.lucroscasa + lucrocasa
        //console.log('History', this.history.length)
        if (this.history.length > 9) {
          //console.log('remove')
          this.history.splice(0, 1)
        }
        this.history.push(target)
        localStorage.setItem('history', JSON.stringify(this.history))

        if (!this.saiudarodada && this.currentid) {
          this.afs.firestore.collection('history').doc(this.currentid).update({
            return: 0,
            multiplier: 0,
            status: 'loss',
          })
        }

        setTimeout(() => {
          this.startround()
        }, 3000)
      })
    })
  }

  async delay() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 100)
    })
  }


}

