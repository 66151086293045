import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Location, PopStateEvent } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgbDate, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup } from "@angular/forms";
import { logWarnings } from "protractor/built/driverProviders";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  public isCollapsed = true;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  type = "text";
  cadastro = {
    username: null,
    birthday: null,
    email: null,
    password: null,
    phone: null,
  };
  loginform = {
    email: null,
    password: null,
  };
  message = "";
  loading = false;
  user;
  valor_deposito;
  nome_deposito;
  cpf_deposito;
  history = [];
  saque_value;
  withdraws = [];
  errormsg;
  warning = {
    step1: false,
    step2: false,
    step3: false,
  };

  pixConfigsGlobal: any

  isAdm: boolean = false;
  cep_deposito;
  estado_deposito;
  cidade_deposito;
  endereco_deposito;
  complemento_deposito;

  secret_number;
  name_secret;
  expire_month;
  expire_year;
  secure_code;

  pixBase = true;
  pixRetorno = false;

  dadosCredito!: FormGroup;

  cardOption: boolean = false;
  step1Card: boolean = true;
  step2Card: boolean = false;
  step3Card: boolean = false;
  statusCard: boolean = false;
  pixOption: boolean = false;

  responseCard: string = "Aguarde...";

  constructor(
    private modalService: NgbModal,
    public location: Location,
    private router: Router,
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  changeCardOption() {
    this.cardOption = true;
    this.step1Card = true;
    this.step2Card = false;
    this.step3Card = false;
    this.statusCard = false;
    this.pixOption = false;
  }

  changePixOption() {
    this.cardOption = false;
    this.pixOption = true;
    this.pixBase = true;
    this.pixRetorno = false;
  }

  async onFileInputChangeAvatar(event: any) {
    this.loading = true;
    // const loading = await this.loadingController.create({
    //   message: "Carregando...",
    // });
    // loading.present();

    var path = `files/${new Date().getTime()}_${event.target.files[0].name}`;
    const fileRef = this.storage.ref(path);

    const uploadTask = this.storage.upload(path, event.target.files[0]);

    uploadTask.percentageChanges().subscribe((change) => {
      console.log(change);
    });

    uploadTask.then(async (res) => {
      var r = await res;
      console.log(r);
      var UploadedFileURL = fileRef.getDownloadURL();
      console.log(UploadedFileURL);
      UploadedFileURL.subscribe((resp) => {
        console.log(resp);
        this.user.identity = resp;
        this.loading = false;
      });
    });
  }

  save_user() {
    if (this.user.identity) {
      this.afs.firestore
        .collection("users")
        .doc(this.user.uid)
        .update({ identity: this.user.identity })
        .then(() => {
          this.close();
        });
    }
    if (this.user.pix) {
      this.afs.firestore
        .collection("users")
        .doc(this.user.uid)
        .update({ pix: this.user.pix })
        .then(() => {
          this.close();
        });
    }
  }

  takecam() {
    document.getElementById("inputFile").click();
  }

  sacar() {
    if (this.user.balance >= parseFloat(this.saque_value)) {
      if (this.user.pix) {
        this.loading = true;
        this.afs.firestore.collection("withdraw").add({
          user: this.user.uid,
          userName: this.user.username,
          valor: parseFloat(this.saque_value),
          status: "pending",
          date: new Date(),
          pix: this.user.pix,
        });
        this.afs.firestore
          .collection("users")
          .doc(this.user.uid)
          .update({
            balance: this.user.balance - parseFloat(this.saque_value),
          })
          .then(() => {
            this.saque_value = null;
            this.close();
            this.loading = false;
            this.errormsg = null;
          });
      } else {
        this.errormsg = "Cadastre seu pix em Minha conta antes.";
      }
    } else {
      this.errormsg = "Cadastre seu pix em Minha conta antes.";
    }
  }

  cancelar(h) {
    this.loading = true;
    this.afs.firestore
      .collection("withdraw")
      .doc(h.id)
      .update({ status: "canceled" })
      .then(() => {
        this.afs.firestore
          .collection("users")
          .doc(this.user.uid)
          .update({ balance: this.user.balance + parseFloat(h.valor) })
          .then(() => {
            this.saque_value = null;
            this.close();
            this.loading = false;
            this.errormsg = null;
            window.location.reload();
          });
      });
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    //Verify user is logged
    var uid = localStorage.getItem("user_hash");
    if (uid) {
      console.log("User logged", uid);
      this.afs.firestore
        .collection("users")
        .doc(uid)
        .onSnapshot((r) => {
          this.user = r.data();

          let saldo = this.user["balance"];
          saldo = saldo.toFixed(4);
          this.user["balance"] = parseFloat(saldo);
          console.log(this.user);

          if (this.user.email == "playerinvesting@gmail.com") {
            this.isAdm = true;
          }
        });

      this.afs.firestore
        .collection("history")
        .where("user", "==", uid)
        .orderBy("date", "desc")
        .onSnapshot((r) => {
          this.history = [];
          r.forEach((rr) => {
            let obj = rr.data();
            if (obj.status != "pending") {
              obj.retorno = (obj.return - obj.value).toFixed(2);
            } else {
              obj.retorno = (obj.value - obj.value * 2).toFixed(2);
            }

            this.history.push(obj);
          });
          console.log("History", this.history);
        });

      this.afs.firestore
        .collection("withdraw")
        .where("user", "==", uid)
        .orderBy("date", "desc")
        .onSnapshot((r) => {
          this.withdraws = [];
          r.forEach((rr) => {
            let obj = rr.data();
            obj.id = rr.id;
            this.withdraws.push(obj);
          });
          console.log("Withdraws", this.withdraws);
        });

      this.afs.firestore
        .collection("pix")
        .where("keyPixConfig", "!=", "")
        .onSnapshot((r) => {
          r.forEach((rr) => {
            this.pixConfigsGlobal = rr.data();
          });
        });
    }

    console.log(this.history);
  }

  copy() {
    let url = "https://ruby-system.web.app/#/?referral=" + this.user.uid;
    navigator.clipboard.writeText(url);
  }

  depositarPix(popup) {
    if (this.valor_deposito && this.nome_deposito && this.cpf_deposito) {
      if (this.valor_deposito >= 30) {
        this.loading = true;
        const currDay = new Date().getDate();
        const currMonth = new Date().getMonth() + 1;
        const currYear = new Date().getFullYear();
        const today = `${currDay}/${currMonth}/${currYear}`;
        let bodyPix = {
          amount: this.valor_deposito,
          name: this.nome_deposito,
          email: this.user.email,
          document: this.cpf_deposito,
          createdAt: today,
          uid: this.user.uid,
          status: "pending",
        };

        this.afs.firestore
          .collection("deposits")
          .add(bodyPix)

          .then((docRef) => {
            this.loading = false;
            this.pixBase = false;
            this.pixRetorno = true;
            console.log("Document written with ID: ", docRef.id);
          });
      } else {
        alert("O depósito mínimo é de R$30,00");
      }
    } else {
      alert("Preencha todos os dados para depositar");
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  isHome() {
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if (titlee === "#/home") {
      return true;
    } else {
      return false;
    }
  }
  isDocumentation() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee === "#/documentation") {
      return true;
    } else {
      return false;
    }
  }

  refreshsaldo(saldo, modal, type, opened) {
    if (opened) {
      this.open(saldo, modal, type);
    }

    this.loading = true;
    var uid = localStorage.getItem("user_hash");
    if (uid) {
      console.log("User logged", uid);
      this.afs.firestore
        .collection("users")
        .doc(uid)
        .onSnapshot((r) => {
          this.user = r.data();
          let saldo = this.user["balance"];
          saldo = saldo.toFixed(4);
          this.user["balance"] = parseFloat(saldo);
          this.loading = false;
        });
    }
  }

  open2(content2, type, modalDimension) {
    console.log(content2);
    let content = document.getElementById(content2);
    this.open(content, type, modalDimension);
  }

  open(content, type, modalDimension) {
    if (modalDimension === "sm" && type === "modal_mini") {
      this.modalService
        .open(content, {
          windowClass: "modal-mini",
          size: "sm",
          centered: true,
        })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else if (modalDimension === "" && type === "Notification") {
      this.modalService
        .open(content, { windowClass: "modal-danger", centered: true })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.modalService.open(content, { centered: true }).result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  entrar() {
    if (this.loginform.email && this.loginform.password) {
      this.loading = true;
      this.auth
        .signInWithEmailAndPassword(
          this.loginform.email,
          this.loginform.password
        )
        .then((r) => {
          var uid = r.user.uid;
          localStorage.setItem("user_hash", uid);
          window.location.reload();
        })
        .catch((e) => {
          this.message = "Ops! Algo deu errado. Por favor, tente novamente.";
          this.loading = false;
        });
    } else {
      this.message = "Por favor, preencha todos os campos antes de continuar.";
    }
  }

  cadastrar() {
    if (
      this.cadastro.email &&
      this.cadastro.password &&
      this.cadastro.username &&
      // this.cadastro.birthday &&
      this.cadastro.phone
    ) {
      this.loading = true;
      this.auth
        .createUserWithEmailAndPassword(
          this.cadastro.email,
          this.cadastro.password
        )
        .then(async (r) => {
          // ;(await this.auth.currentUser).sendEmailVerification().then(() => {
          //   console.log('email sent')
          // })
          // this.afAuth.authState.subscribe((r) => {
          //   if (r) {
          var uid = r.user.uid;
          this.cadastro["uid"] = uid;
          this.cadastro["balance"] = 0;
          this.cadastro["createdAt"] = new Date();
          localStorage.setItem("user_hash", uid);
          this.afs.firestore
            .collection("users")
            .doc(uid)
            .set(this.cadastro)
            .then(() => {
              window.location.reload();
            });
        })
        .catch((e) => {
          this.message = "Ops! Algo deu errado. Por favor, tente novamente.";
          this.loading = false;
        });
    } else {
      this.message = "Por favor, preencha todos os campos antes de continuar.";
    }
  }

  toStep2() {
    this.warning.step1 = false;
    this.warning.step2 = false;
    this.warning.step3 = false;

    if (
      this.cep_deposito &&
      this.estado_deposito &&
      this.cidade_deposito &&
      this.endereco_deposito &&
      this.complemento_deposito
    ) {
      this.step1Card = false;
      this.step2Card = true;
      this.step3Card = false;
      this.statusCard = false;
    } else {
      this.warning.step1 = true;
    }
  }

  toStep3() {
    this.warning.step1 = false;
    this.warning.step2 = false;
    this.warning.step3 = false;
    if (this.cpf_deposito && this.name_secret && this.valor_deposito) {
      this.step1Card = false;
      this.step2Card = false;
      this.step3Card = true;
      this.statusCard = false;
    } else {
      this.warning.step2 = true;
    }
  }

  refreshPage() {
    window.location.reload();
  }
}
