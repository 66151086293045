import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ManageComponent } from './manage/manage.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'crash', component: HomeComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'manage', component: ManageComponent },
  // { path: 'register',           component: SignupComponent },
  // { path: 'login',          component: LoginComponent },
  // { path: '', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
