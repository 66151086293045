import { Component, OnInit } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'

@Component({
  selector: 'admin-landing',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  focus: any
  focus1: any
  withdraws = []

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    this.afs.firestore
      .collection('withdraw')
      .orderBy('date', 'desc')
      .get()
      .then((r) => {
        let withdraws = [];
        r.forEach((rr) => {
          let obj = rr.data()
          obj.id = rr.id
          withdraws.push(obj)
        })

        this.withdraws = withdraws
      })
  }

  pago(h){
    this.afs.firestore.collection('withdraw').doc(h.id).update({status: 'ready', updateDate: new Date}).then(() => {
      window.location.reload()
    })
  }

  naopago(h){
    this.afs.firestore.collection('withdraw').doc(h.id).update({status: 'pending', updateDate: new Date}).then(() => {
      window.location.reload()
    })
  }

}
