import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-manage",
  templateUrl: "./manage.component.html",
  styleUrls: ["./manage.component.scss"],
})
export class ManageComponent implements OnInit {
  depositScreen: any = true;
  withdrawScreen: any = false;
  pixScreen: any = false;
  pixConfigsGlobal: any;

  saquesTemp: any = [];
  saques: any = [];

  depositsTemp: any = [];
  deposits: any = [];

  user: any;

  formPix = {
    key: "",
    image: "",
  };

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    var uid = localStorage.getItem("user_hash");
    if (uid) {
      console.log("User logged", uid);
      this.afs.firestore
        .collection("users")
        .doc(uid)
        .onSnapshot((r) => {
          this.user = r.data();
        });
    }

    let withdrawDocs = this.afs.firestore.collection(`withdraw`);
    withdrawDocs.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let obj = doc.data();
        obj.id = doc.id;
        this.saquesTemp.push(obj);
        this.saques = this.saquesTemp.filter((rr) => rr.status == "pending");
      });
    });

    let depositDocs = this.afs.firestore.collection(`deposits`);
    depositDocs.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let obj = doc.data();
        obj.id = doc.id;
        this.depositsTemp.push(obj);
        this.deposits = this.depositsTemp.filter(
          (rr) => rr.status == "pending"
        );
      });
    });

    this.afs.firestore
      .collection("pix")
      .where("keyPixConfig", "!=", "")
      .onSnapshot((r) => {
        r.forEach((rr) => {
          this.pixConfigsGlobal = rr.data();
          this.formPix.key = this.pixConfigsGlobal.keyPixConfig;
          this.formPix.image = this.pixConfigsGlobal.imgPix;
        });
      });
  }

  setPay(item: any) {
    this.afs
      .collection("withdraw")
      .doc(item.id)
      .update({ status: "pago" })
      .then(() => {
        window.location.reload();
      });
  }

  setNotPay(item: any) {
    this.afs
      .collection("withdraw")
      .doc(item.id)
      .update({ status: "denied" })
      .then(async () => {
        await this.afs.firestore
          .collection("users")
          .doc(item.user)
          .update({
            balance: this.user.balance + parseFloat(item.valor),
          });

        window.location.reload();
      });
  }

  confirmDeposit(item: any) {
    this.afs
      .collection("deposits")
      .doc(item.id)
      .update({ status: "success" })
      .then(async () => {
        await this.afs.firestore
          .collection("users")
          .doc(item.uid)
          .update({
            balance: this.user.balance + parseFloat(item.amount),
          });

        window.location.reload();
      });
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = "pix/piximage";
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.downloadURL = fileRef.getDownloadURL())))
      .subscribe();
  }

  updatePix() {
    this.downloadURL.subscribe((r) => {
      this.afs
        .collection("pix")
        .doc("QTZ3MERPh0xLk7jb08Gg")
        .update({
          imgPix: r,
          keyPixConfig: this.formPix.key,
        })
        .then(async () => {
          window.location.reload();
        });
    });
  }

  toDeny(item: any) {
    this.afs
      .collection("deposits")
      .doc(item.id)
      .update({ status: "denied" })
      .then(async () => {
        window.location.reload();
      });
  }

  toDeposits() {
    this.depositScreen = true;
    this.withdrawScreen = false;
    this.pixScreen = false;
  }

  toWithdraws() {
    this.depositScreen = false;
    this.withdrawScreen = true;
    this.pixScreen = false;
  }

  toPix() {
    this.depositScreen = false;
    this.withdrawScreen = false;
    this.pixScreen = true;
  }
}
